"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setKZV = exports.setStoreFromParams = void 0;
const hvmStore_1 = require("@/state/hvmStore");
const base_1 = require("../../../base");
function setStoreFromParams(routeParams) {
    let kzvInfo = undefined;
    if (routeParams) {
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.kzv) {
            let ki = routeParams.kzv;
            if (routeParams.reftype) {
                ki = `${ki}-${routeParams.reftype}`;
            }
            kzvInfo = setKZV(ki);
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.ignorekzv) {
            hvmStore_1.hvmStore.commit.setIgnoreKzv(routeParams.ignorekzv === 'true');
        }
        if ((routeParams === null || routeParams === void 0 ? void 0 : routeParams.von) && (routeParams === null || routeParams === void 0 ? void 0 : routeParams.bis)) {
            hvmStore_1.hvmStore.commit.setDate({
                startDate: (0, base_1.roseDayjs)(routeParams.von).format('YYYY-MM-DD'),
                endDate: (0, base_1.roseDayjs)(routeParams.bis).format('YYYY-MM-DD'),
            });
        }
        if ((routeParams === null || routeParams === void 0 ? void 0 : routeParams.altervon) && (routeParams === null || routeParams === void 0 ? void 0 : routeParams.alterbis)) {
            hvmStore_1.hvmStore.commit.setAgeRange([routeParams.altervon, routeParams.alterbis]);
        }
    }
    return kzvInfo;
}
exports.setStoreFromParams = setStoreFromParams;
function setKZV(kzvInfo) {
    console.log('setKZV', kzvInfo);
    const [kzv, refType] = kzvInfo.split('-');
    hvmStore_1.hvmStore.commit.setKZVInfo({ kzv, refType });
    return { kzv, refType, orig: kzvInfo };
}
exports.setKZV = setKZV;
