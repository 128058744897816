"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.array.concat.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var lodash_1 = require("lodash");

var vue_1 = require("vue");

var common_ui_1 = require("../../../../common-ui");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    budget: {
      type: Number,
      "default": null
    },
    budgetRef: {
      type: Number,
      "default": null
    },
    betrag: {
      type: Number,
      "default": null
    },
    punkte: {
      type: Number,
      "default": null
    },
    punktwert: {
      type: Number,
      "default": null
    },
    cases: {
      type: Number,
      "default": null
    },
    property: {
      type: String,
      "default": 'euro'
    },
    light: {
      type: Boolean,
      "default": false
    },
    caseName: {
      type: Array,
      "default": ['-', '-']
    }
  },
  computed: {
    punktwertString: function punktwertString() {
      return (0, lodash_1.isNil)(this.punktwert) ? undefined : "Punktwert: ".concat(common_ui_1.decimalFilter.filters.decimal(this.punktwert, 2), " \u20AC/P");
    }
  },
  methods: {
    logData: function logData() {
      console.log("hvmbayerncell: ".concat(this.budget, " ").concat((0, lodash_1.isNumber)(this.budget), " ").concat(this.punkte, " ").concat(this.betrag, " ").concat(this.cases));
    }
  }
});