/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./HVMWestfalenLippe2.vue?vue&type=template&id=0e97e5fd&scoped=true&lang=pug&"
import script from "./HVMWestfalenLippe2.vue?vue&type=script&lang=ts&"
export * from "./HVMWestfalenLippe2.vue?vue&type=script&lang=ts&"
import style0 from "./HVMWestfalenLippe2.vue?vue&type=style&index=0&id=0e97e5fd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e97e5fd",
  null
  
)

export default component.exports