"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.behandlerFilter = void 0;
const behandlerService_1 = require("@/services/behandlerService");
const lodash_1 = require("lodash");
exports.behandlerFilter = {
    filters: {
        behandler(id, field = 'displayText', addId = false, fallback = '?') {
            const bs = new behandlerService_1.BehandlerService();
            if (field === 'typ') {
                let b = bs.getBehandler(id);
                if (b && b.info) {
                    return b.info.zahnarzt ? 'ZA' : b.info.pzr ? 'PZR' : fallback;
                }
                return fallback;
            }
            let s = bs.getProperty(id, field);
            if (addId) {
                s += ` (${id})`;
            }
            return (0, lodash_1.isNil)(s) ? fallback : s;
        },
    },
};
