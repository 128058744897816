"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      showHint: true,
      text: 'Dieser Dialog befindet sich noch in der Entwicklung. Er gibt schon eine gute Idee wie es mal werden wird. Aber die Ergebnisse sind noch ohne Gewähr. Trotzdem ist Feedback willkommen.',
      fillColor: '#f39c12e0',
      textColor: '#fff'
    };
  }
});